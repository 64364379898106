import { useSelector } from 'react-redux'

const CalendarInvitees = (project, campaigntrackInvitees) => {
  const { offices, users } = useSelector((reduxState) => reduxState.entities)

  if (project?.calendar_invite_emails?.length) {
    return project.calendar_invite_emails
  }

  if (campaigntrackInvitees?.length) {
    return campaigntrackInvitees
  }

  let officeInvitees = Object.values(offices)?.find((x) => x.id === project?.office)?.calendar_invite_emails || []
  let projectOwner = Object.values(users)?.find((x) => x.id === project?.owner)?.email || null
  let projectCollaborators =
    Object.values(users)
      ?.filter((x) => project?.collaborators?.includes(x.id))
      ?.map((x) => x.email) || []

  let defaultInvitees = [
    ...new Set([projectOwner, ...projectCollaborators, ...officeInvitees].filter((x) => ![null, undefined].includes(x))),
  ]

  return defaultInvitees
}

export default CalendarInvitees
