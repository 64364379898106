import { arrayHasValues } from 'utils/validations'

const state = {
  id: null,
  display_name: '',
  office: '',
  owner: '',
  parts: [],
  collaborators: [],
  calendar_invite_emails: [],
}

export const requiredFields = [{ key: 'display_name' }, { key: 'owner' }, { key: 'calendar_invite_emails', validation: arrayHasValues }]

export default state
