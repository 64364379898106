import { useSelector } from 'react-redux'

import useReduxAction from '../hooks/useReduxAction'

const useCurrentUser = () => {
  useReduxAction('currentUser', 'loadCurrentUser', {}, [], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loaded, loading } = entityReducer
      return !loaded && !loading && !errors.length
    },
  })

  const entityReducer = useSelector((reduxState) => reduxState.currentUser)
  const { errors, loaded, loading, result: currentUser } = entityReducer

  const timeZone = 'UTC'
  const displayTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'Australia/Sydney'

  return {
    currentUser,
    errors,
    loaded,
    loading,
    timeZone,
    displayTimeZone,
  }
}

export default useCurrentUser
