import { normalize } from 'normalizr'
import { Schemas } from 'redux/schema'
import { updateEntities } from 'redux/entities'

import apiV2 from 'utils/apiV2'

import { handleError } from '../utils'

const FETCH_REQUEST = 'contenthouse/sqlServiceJob/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/sqlServiceJob/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/sqlServiceJob/FETCH_FAILURE'

const CREATE_REQUEST = 'contenthouse/sqlServiceJob/CREATE_REQUEST'
const CREATE_SUCCESS = 'contenthouse/sqlServiceJob/CREATE_SUCCESS'
const CREATE_FAILURE = 'contenthouse/sqlServiceJob/CREATE_FAILURE'

const UPDATE_REQUEST = 'contenthouse/sqlServiceJob/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'contenthouse/sqlServiceJob/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'contenthouse/sqlServiceJob/UPDATE_FAILURE'

// Initial State
const initialState = {
  additionalInfo: {},
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedIds: [],
  loadedForKeys: [],
  loading: false,
  results: {},
  updating: false,
}

// Actions
export function fetchRequest(payload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess(payload = {}) {
  return {
    type: FETCH_SUCCESS,
    results: payload.data,
    additionalInfo: payload.additionalInfo,
  }
}

export function fetchFailure(errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function loadSqlServiceJob(serviceJobId, options) {
  return (dispatch, getState) => {
    const state = getState()
    const loadedIds = [...state.sqlServiceJobs.loadedIds]

    if (serviceJobId && !loadedIds.includes(serviceJobId)) {
      loadedIds.push(serviceJobId)
    }

    dispatch(fetchRequest({ loadedIds }))

    const promise = apiV2(`/sql/generic/service-jobs/${serviceJobId}`, options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.SQL_SERVICE_JOB)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function assignServiceJobStatus(serviceJob, options) {
  const config = {
    method: 'POST',
    data: serviceJob,
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = apiV2(`/sql/service-jobs/${serviceJob.id}/workflow`, { ...options, includes: 'details' }, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.SQL_SERVICE_JOB)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })
    return promise
  }
}

export default function reducer(state = initialState, action = {}) {
  const { additionalInfo, type, errors, loadedForKeys, loadedIds, results } = action

  switch (type) {
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: loadedForKeys || state.loadedForKeys,
        loadedIds: loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        errors: [],
        loaded: true,
        loading: false,
        results: results || state.results,
        additionalInfo: additionalInfo || state.additionalInfo,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors,
      }
    default:
      return state
  }
}
