import apiV2 from 'utils/apiV2'

import { handleError } from '../utils'

const FETCH_REQUEST = 'contenthouse/projectPlatform/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/projectPlatform/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/projectPlatform/FETCH_FAILURE'

const CREATE_REQUEST = 'contenthouse/projectPlatform/CREATE_REQUEST'
const CREATE_SUCCESS = 'contenthouse/projectPlatform/CREATE_SUCCESS'
const CREATE_FAILURE = 'contenthouse/projectPlatform/CREATE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  errors: [],
  loaded: false,
  loading: false,
  result: {},
}

// Actions
export function fetchRequest() {
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(payload = {}) {
  return {
    type: FETCH_SUCCESS,
    payload,
  }
}

export function fetchFailure(errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

// Action Creators
export function redirectPlatform(platform, options) {
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = apiV2(`/redirect/${platform}`, options)
      .then(({ data }) => {
        dispatch(fetchSuccess(data))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function loadPropertyFromOrder(platform, options) {
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = apiV2(`/redirect/${platform}/return`, options)
      .then(({ data }) => {
        dispatch(fetchSuccess(data))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function pushPlatformAssets(assetParams, options = {}) {
  const config = {
    method: 'POST',
    data: assetParams.assets,
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = apiV2(`/assets/push/${assetParams.platform}`, options, config)
      .then(({ data }) => {
        dispatch(createSuccess())

        return { success: true, result: data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload, errors } = action

  switch (type) {
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
        result: payload,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors,
      }
    default:
      return state
  }
}
