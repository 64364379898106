import * as appointments from './appointment'
import * as assets from './asset'
import * as currentUser from './currentUser'
import * as ctBookingCampaigns from './ctBookingCampaign'
import * as entities from '../entities'
import * as favourites from './favourite'
import * as jobs from './job'
import * as merchandiseOrderItems from './merchandiseOrderItem'
import * as merchandises from './merchandise'
import * as offices from './office'
import * as orderItems from './orderItem'
import * as orders from './order'
import * as posts from './post'
import * as prices from './price'
import * as productParts from './productPart'
import * as products from './product'
import * as projectBookingContacts from './projectBookingContact'
import * as projectFiles from './projectFile'
import * as projectInvitees from './projectInvitee'
import * as projectSlots from './projectSlot'
import * as projectTemplates from './projectTemplate'
import * as projects from './project'
import * as projectsPlatform from './projectPlatform'
import * as projectsSearch from './projectSearch'
import * as providers from './provider'
import * as tasks from './task'
import * as taskSheets from './taskSheet'
import * as users from './user'
import * as zoneProvidersEstimates from './zoneProvidersEstimate'
import * as zones from './zone'

import * as sqlAssets from './sqlAsset'
import * as sqlServiceJobs from './sqlServiceJob'

import * as enums from './enum'

const modules = {
  // cosmos
  appointments,
  assets,
  currentUser,
  ctBookingCampaigns,
  entities,
  enums,
  favourites,
  jobs,
  merchandiseOrderItems,
  merchandises,
  offices,
  orderItems,
  orders,
  posts,
  prices,
  productParts,
  products,
  projectBookingContacts,
  projectFiles,
  projectInvitees,
  projectSlots,
  projectTemplates,
  projects,
  projectsPlatform,
  projectsSearch,
  providers,
  tasks,
  taskSheets,
  users,
  zoneProvidersEstimates,
  zones,
  // sql
  sqlAssets,
  sqlServiceJobs,
}

export const reducers = Object.keys(modules).reduce((acc, moduleKey) => {
  const module = modules[moduleKey]
  acc[moduleKey] = module.default

  return acc
}, {})

export default modules
