import Axios from 'axios'
import { normalize } from 'normalizr'
import { Schemas } from 'redux/schema'
import { updateEntities } from 'redux/entities'

import { getAccessToken } from 'utils/accessToken'
import apiV2, { baseURL } from 'utils/apiV2'

import { handleError } from '../utils'

const FETCH_REQUEST = 'contenthouse/sqlAsset/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/sqlAsset/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/sqlAsset/FETCH_FAILURE'

const CREATE_REQUEST = 'contenthouse/sqlAsset/CREATE_REQUEST'
const CREATE_SUCCESS = 'contenthouse/sqlAsset/CREATE_SUCCESS'
const CREATE_FAILURE = 'contenthouse/sqlAsset/CREATE_FAILURE'

const UPDATE_REQUEST = 'contenthouse/sqlAsset/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'contenthouse/sqlAsset/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'contenthouse/sqlAsset/UPDATE_FAILURE'

const DELETE_REQUEST = 'contenthouse/sqlAsset/DELETE_REQUEST'
const DELETE_SUCCESS = 'contenthouse/sqlAsset/DELETE_SUCCESS'
const DELETE_FAILURE = 'contenthouse/sqlAsset/DELETE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loading: false,
  result: {},
  updating: false,
}

// Actions
export function fetchRequest(payload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
  }
}

export function fetchSuccess(payload = {}) {
  return {
    type: FETCH_SUCCESS,
    payload,
  }
}

export function fetchFailure(errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest() {
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []) {
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadSqlAssets(options) {
  const { entityKey } = options || {}

  return (dispatch, getState) => {
    const loadedForKeys = [...getState().sqlAssets.loadedForKeys]
    if (entityKey && !loadedForKeys.includes(entityKey)) {
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = apiV2('/sql/generic/assets', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.SQL_ASSET_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess(data))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function createSqlAsset(asset, options) {
  const axios = Axios.create({
    baseURL,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  const accessToken = getAccessToken()

  return (dispatch) => {
    dispatch(createRequest())

    const promise = axios({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      url: `/sql/generic/assets`,
      data: asset,
      params: options,
    })
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.SQL_ASSET)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })
    return promise
  }
}

export function updateSqlAsset(asset, options) {
  const axios = Axios.create({
    baseURL,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  const accessToken = getAccessToken()

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = axios({
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      url: `/sql/generic/assets/${asset.id}`,
      data: asset.data,
      params: options,
    })
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.SQL_ASSET)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })
    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload, errors } = action

  switch (type) {
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
        result: payload.continuationToken,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors,
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors,
      }
    default:
      return state
  }
}
